import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import AppStore from './AppStore'
import BaseStore from './BaseStore'
import { SellStore } from './SellStore'

export class ContractorDebtStore extends BaseStore {
  @observable agent_debt = null
  @observable currency = null

  constructor() {
    super()
    makeObservable(this)
  }

  async localGet(...args) {
    if (args[0].indexOf('/', 1) > 0) {
      //when fetching item we don't want to use realm
      const data = await requester.get(...args)
      return data.data
    }
    return super.localGet(...args)
  }

  @action
  setCurrency = c => (this.currency = c)

  setData(data, count) {
    data && data.length > 0 && this.setCurrency(data[0].currency)
    super.setData(ContractorDebtStore.countDebts(data), count)
    return ContractorDebtStore.getOfflineStores().then(this.setOfflineStores)
  }

  static countDebts(data, contractor_ids) {
    let items = []
    let ids = {}
    for (let item of data) {
      if (contractor_ids && !contractor_ids.includes(item.contractor_id))
        continue
      let id,
        index = -1
      if (!item.is_dirty) {
        const { debt_amount, wod_amount } = item
        id = item.id
        if (typeof ids[id] !== 'undefined') {
          items[ids[id]].sale_amount = item.sale_amount
          items[ids[id]].debt_amount =
            1 * items[ids[id]].debt_amount + 1 * debt_amount
          items[ids[id]].contractor = item.contractor
          items[ids[id]].contractor_address = item.contractor_address
          items[ids[id]].inn = item.inn
          items[ids[id]].contractor_type = item.contractor_type
          items[ids[id]].without_ettn = item.without_ettn
          items[ids[id]].is_keep_user = item.is_keep_user
          items[ids[id]].wod_amount =
            1 * items[ids[id]].wod_amount + 1 * wod_amount
          items[ids[id]].diff =
            items[ids[id]].debt_amount - items[ids[id]].wod_amount
        } else {
          index =
            items.push({
              ...item,
              debt_amount,
              wod_amount,
              diff: debt_amount - wod_amount,
            }) - 1
        }
      } else {
        id = `${item.contractor_id}:${item.rec_user}`
        if (typeof ids[id] !== 'undefined') {
          items[ids[id]].wod_amount =
            1 * items[ids[id]].wod_amount + 1 * item.wod_amount
          items[ids[id]].diff =
            items[ids[id]].debt_amount - items[ids[id]].wod_amount
        } else {
          index =
            items.push({
              id: id,
              sale_amount: 0,
              wod_amount: item.wod_amount || 0,
              debt_amount: 0,
              diff: 0,
              contractor_id: item.contractor_id,
              rec_user: item.rec_user,
              contractor: item.contractor,
              contractor_address: item.contractor_address,
              inn: item.inn,
              contractor_type: item.contractor_type,
              without_ettn: item.without_ettn,
              is_keep_user: item.is_keep_user,
            }) - 1
        }
      }
      ids[id] = index > -1 ? index : ids[id]
    }
    return items
  }

  static async getOfflineStores(contractor_ids) {
    let stores = {}
    try {
      let sales = await localDB.get(
        '/sales',
        { 'filter[sale_status]': 'new' },
        null,
        true,
        true,
      )
      ContractorDebtStore.countStores(
        stores,
        sales ? sales.list : [],
        contractor_ids,
      )
    } catch (e) {
      console.log(e)
    }
    return stores
  }

  static countStores(stores, items, contractor_ids) {
    for (let item of items) {
      if (item.sale_status !== 'new' || !item.is_dirty) continue
      if (!item.upd_date) {
        ContractorDebtStore.setStoreItem(item, stores, contractor_ids)
        continue
      }
      let hp = [{ pa: 0, spa: 0 }]
      let history_pay_amount = [...item.data.history_pay_amount]
      let products = [
        ...item.data.products.map(p => ({ ...p, history: [...p.history] })),
      ]
      let q = {}
      for (let i = 0; i < history_pay_amount.length; i++) {
        let pa = { ...history_pay_amount[i] }
        const hl = hp.length
        for (let j = 0; j < products.length; j++) {
          let p = { ...products[j] }
          if (!q[p.nomenclature_id]) {
            q[p.nomenclature_id] = {
              quantity: 0,
              return: 0,
              defective: 0,
              price: 0,
              min_price: 0,
            }
          }
          const pa_date =
            history_pay_amount.length > i + 1
              ? moment(history_pay_amount[i + 1].rec_date)
              : moment()
          while (
            p.history.length > 0 &&
            moment(p.history[0].rec_date).isBefore(pa_date)
          ) {
            let h = { ...p.history.shift() }
            q[p.nomenclature_id].quantity += parseFloat(h.quantity || '0')
            q[p.nomenclature_id].return += parseFloat(h.return || '0')
            q[p.nomenclature_id].defective += parseFloat(h.defective || '0')
            q[p.nomenclature_id].price += parseFloat(h.price || '0')
            q[p.nomenclature_id].min_price += parseFloat(h.min_price || '0')
            let qh = q[p.nomenclature_id]
            let pay_amount = parseFloat(pa.pay_amount || 0)
            if (pay_amount < 0) pay_amount = 0
            hp.push({
              pa: hp[hp.length - 1].pa + pay_amount,
              spa: SellStore.countItemAmount(qh, true),
              rec_date: h.rec_date,
              contractor_id: pa.contractor_id,
            })
            pa.pay_amount = 0
          }
          if (p.history.length === 0) {
            products.splice(j, 1)
            j--
          }
        }
        if (hl === hp.length) {
          let pay_amount = parseFloat(pa.pay_amount || 0)
          if (pay_amount < 0) pay_amount = 0
          hp.push({
            pa: hp[hp.length - 1].pa + pa,
            spa: hp[hp.length - 1].spa,
            rec_date: pa.rec_date,
            contractor_id: pa.contractor_id,
          })
        }
      }
      hp.shift()
      const upd_date = moment(item.upd_date)
      let hpi = null
      for (const hpj of hp) {
        if (upd_date.isBefore(hpj.rec_date)) break
        hpi = hpj
      }
      if (
        hpi &&
        (!contractor_ids || contractor_ids.includes(hpi.contractor_id))
      ) {
        const key = ContractorDebtStore.storeInit(
          hpi.contractor_id,
          item.rec_user,
          stores,
        )
        stores[key].total += Math.round(hpi.pa > hpi.spa ? -hpi.spa : -hpi.pa)
        stores[key].wod += Math.round(hpi.pa > hpi.spa ? hpi.spa - hpi.pa : 0)
        stores[key].debt += Math.round(hpi.pa < hpi.spa ? hpi.pa - hpi.spa : 0)
      }
      hpi = hp.pop()
      if (contractor_ids && !contractor_ids.includes(hpi.contractor_id))
        continue
      const key = ContractorDebtStore.storeInit(
        hpi.contractor_id,
        item.rec_user,
        stores,
      )
      ContractorDebtStore.writeStoreKey(stores, key, hpi.pa, hpi.spa)
    }
  }

  static setStoreItem(item, stores, contractor_ids) {
    if (contractor_ids && !contractor_ids.includes(item.contractor_id)) return
    const key = ContractorDebtStore.storeInit(
      item.contractor_id,
      item.rec_user,
      stores,
    )
    let total = 0
    for (let p of item.data.products) {
      total += SellStore.countItemAmount(p, true)
    }
    const paid = parseFloat(item.pay_amount || 0)
    ContractorDebtStore.writeStoreKey(stores, key, paid, total)
  }

  static storeInit(contractor_id, rec_user, stores) {
    const key = `${contractor_id}:${rec_user}`
    if (!stores[key])
      stores[key] = {
        total: 0,
        wod: 0,
        debt: 0,
        contractor_id: contractor_id,
        rec_user: rec_user,
      }
    return key
  }

  static writeStoreKey(stores, key, paid, total) {
    stores[key].total += Math.round(paid > total ? total : paid)
    stores[key].wod += Math.round(paid > total ? paid - total : 0)
    stores[key].debt += Math.round(paid < total ? total - paid : 0)
  }

  @action.bound
  setOfflineStores(stores) {
    for (let item of this.items) {
      if (stores[item.id]) {
        item.sale_amount = 1 * item.sale_amount + stores[item.id].total
        item.wod_amount = 1 * item.wod_amount + stores[item.id].wod
        item.debt_amount = 1 * item.debt_amount + stores[item.id].debt
        item.diff = item.debt_amount - item.wod_amount
        delete stores[item.id]
      }
      if (Object.keys(stores).length === 0) {
        break
      }
    }

    for (let id of Object.keys(stores)) {
      this.items.push({
        id: id,
        contractor_id: stores[id].contractor_id,
        rec_user: stores[id].rec_user,
        contractor: null,
        contractor_address: null,
        inn: null,
        contractor_type: null,
        without_ettn: null,
        is_keep_user: null,
        sale_amount: stores[id].total,
        wod_amount: stores[id].wod,
        debt_amount: stores[id].debt,
        diff: stores[id].debt - stores[id].wod,
      })
    }
  }

  @computed
  get totalAmount() {
    return this.items.reduce(ContractorDebtStore.reduceTotal, {
      sales: 0,
      debt: 0,
    })
  }

  static reduceTotal(a, i) {
    a.sales += parseFloat(i.sale_amount) + parseFloat(i.wod_amount)
    a.debt += 1 * i.diff
    return a
  }

  get pagesCount() {
    return 1
  }

  loadAgentDebt() {
    const user_id =
      this.filters.get('user_id') ||
      (AppStore.user_info && AppStore.user_info.id)
    this.setAgentDebt({ debt: null })
    localDB.hasConnection &&
      user_id &&
      ContractorDebtStore.getAgentDebt(user_id).then(this.setAgentDebt)
  }

  static async getAgentDebt(user_id) {
    const data = await requester.get('/agentreport/debt/' + user_id)
    return data.data
  }

  @action
  setAgentDebt = ({ debt }) => {
    this.agent_debt = debt
  }

  async fetchMany() {
    this.loadAgentDebt()
    return await super.fetchMany()
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'user_id', 'contractor']
  }
}

const store = new ContractorDebtStore()
export default store
